import Analytics from '@hh.ru/analytics-js';

import getOpenedTabCount from 'HHC/Performance/TabCounter';

/* Метрики, которые отправляем батчем, они происходят на каждую загрузку страницы */
const FIRST_BATCH = [
    'fcp',
    'lux-init-time',
    'lux-first-render-time',
    'lux-init-time-from-nav-start',
    'classic-components-init-time',
    'ttfb',
    'bff_xhh_time',
    'network_xhh_time',
];

interface Metric {
    metric_name: string;
    metric_value: number;
    device_memory: number;
    hardware_concurrency: number;
    effective_type?: string;
    rtt?: number;
    downlink?: number;
    service_name: string;
    client_dt: number;
    params?: Record<string, string>;
}

let notSendedMetrics: Metric[] = [];
let needSendToNewKafkaCluster = false;

const convertToAnalytics = (metricName: string, value: number): Metric => {
    return {
        metric_name: metricName,
        metric_value: value,
        device_memory: (navigator as unknown as { deviceMemory: number })?.deviceMemory,
        hardware_concurrency: navigator?.hardwareConcurrency,
        effective_type: navigator?.connection?.effectiveType,
        rtt: navigator?.connection?.rtt,
        downlink: navigator?.connection?.downlink,
        service_name: 'xhh',
        client_dt: Date.now(),
        params: {
            openedTabCount: `${getOpenedTabCount()}`,
            visibilityState: document.visibilityState,
        },
    };
};

export const sendBatchAnalytics = (metrics: { metricName: string; value: number }[]) => {
    const metricsForSend = metrics.map((metric) => convertToAnalytics(metric.metricName, metric.value));
    Analytics.sendHHEvent('web_performance', undefined, { metrics: metricsForSend }, true);
};

export default (metricName: string, value: number) => {
    const webPerformanceMetric = convertToAnalytics(metricName, value);
    notSendedMetrics.push(webPerformanceMetric);

    const notSendedMetricsName = notSendedMetrics.map((metric) => metric.metric_name);

    if (
        notSendedMetrics.length >= FIRST_BATCH.length &&
        FIRST_BATCH.every((metricName) => notSendedMetricsName.includes(metricName))
    ) {
        needSendToNewKafkaCluster = true;
    }

    if (needSendToNewKafkaCluster) {
        const data = [...notSendedMetrics];
        notSendedMetrics = [];

        const result = { metrics: data.map((metric) => metric) };
        Analytics.sendHHEvent('web_performance', undefined, result, true);
    }
};
