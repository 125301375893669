import axios from 'axios';

// tempexp_194377_next_line
import Analytics from '@hh.ru/analytics-js';
import urlParser from 'bloko/common/urlParser';

import Debug from 'HHC/Debug';

import defaultHeadersInterceptor from 'Modules/defaultHeadersInterceptor';
import errorInterceptor from 'Modules/errorInterceptor';
import { getHhtmHeaders } from 'Modules/hhtm';
import { qaState, AXIOS_REQUEST } from 'Modules/qaState';
import successInterceptor from 'Modules/successInterceptor';
import xsrfInterceptor from 'Modules/xsrfInterceptor';

axios.defaults.withCredentials = true;
axios.defaults.paramsSerializer = urlParser.stringify;
axios.interceptors.request.use(xsrfInterceptor);
axios.interceptors.request.use(defaultHeadersInterceptor);
axios.interceptors.response.use(successInterceptor, errorInterceptor);

// tempexp_194377_start
const logByCrossdomainRequests = (config) => {
    const parsedUrl = urlParser(config.url);
    if (
        typeof window !== 'undefined' &&
        config.url.startsWith('http') &&
        window.location.host.includes('rabota.by') &&
        !parsedUrl.host.includes('rabota.by')
    ) {
        Analytics.sendHHEvent('axios_absolute_url_request', {
            locationHost: window.location.host,
            locationPathname: window.location.pathname,
            requestHost: parsedUrl.host,
            requestPathname: parsedUrl.pathname,
        });
    }
    return config;
};
axios.interceptors.request.use(logByCrossdomainRequests);
// tempexp_194377_end

// For autotests
const incrementRequests = () => {
    const value = qaState[AXIOS_REQUEST];
    qaState[AXIOS_REQUEST] = value + 1;
};

const decrementRequests = () => {
    const value = qaState[AXIOS_REQUEST];
    qaState[AXIOS_REQUEST] = value - 1;
};

const injectHhtm = (config) => {
    const hhtmHeaders = getHhtmHeaders(config.url);
    config.headers.common = { ...hhtmHeaders, ...config.headers.common };
};

const requestCountStore = {};
const MAX_COUNT_PER_WINDOW_SECONDS = 10;
const WIN_MIN = 3 * 1000;
const detectAbnormalNumberRequests = (config) => {
    if (config.method !== 'get') {
        return;
    }

    try {
        const key = `${config.url}${JSON.stringify(config.params)}`;
        requestCountStore[key] = requestCountStore[key] ?? { count: 0, firstRequestTime: performance.now() };
        requestCountStore[key].count += 1;

        if (requestCountStore[key].count >= MAX_COUNT_PER_WINDOW_SECONDS) {
            const timeDelta = performance.now() - requestCountStore[key].firstRequestTime;
            if (timeDelta < WIN_MIN) {
                Debug.log('error out', `Abnormal number of requests: ${config.url}`, {
                    timeDelta,
                    count: requestCountStore[key].count,
                    config: {
                        method: config.method,
                        params: config.params,
                        timeout: config.timeout,
                        signalAborted: config?.signal?.aborted,
                        data: config.data,
                        isGetPage: config.isGetPage,
                    },
                });
            } else {
                delete requestCountStore[config.url];
            }
        }
    } catch (error) {
        Debug.log('error out', error);
    }
};

axios.interceptors.request.use(
    (config) => {
        incrementRequests();
        injectHhtm(config);
        detectAbnormalNumberRequests(config);
        return config;
    },
    (error) => Promise.reject(error)
);

axios.interceptors.response.use(
    (response) => {
        decrementRequests();
        return response;
    },
    (error) => {
        decrementRequests();
        return Promise.reject(error);
    }
);
