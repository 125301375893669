import sendToAnalytics from 'HHC/Performance/SendToAnalytics';

export default () => {
    const criticalPathMetric = performance.getEntriesByName('critcal-path-head');

    if ('startTime' in criticalPathMetric) {
        sendToAnalytics('critical-path-head', criticalPathMetric.startTime as number);
    }

    const performanceEntries = performance.getEntriesByType('navigation');

    if (performanceEntries.length === 0) {
        return;
    }

    const navigationEntry = performanceEntries[0];

    if ('domInteractive' in navigationEntry) {
        sendToAnalytics('dom-interactive', navigationEntry.domInteractive as number);
    }

    if ('domContentLoadedEventStart' in navigationEntry) {
        sendToAnalytics('dom-content-loaded-event-start', navigationEntry.domContentLoadedEventStart as number);
    }
};
