import Storage from 'bloko/common/storage/LocalStorageWrapper';

const LS_TAB_ID_PREFIX = 'HH-TAB-COUNT-ID-';
const ABNORMAL_TAB_COUNT = 150;

export default () => {
    try {
        const lsKeys = Object.keys(localStorage);

        const tab = lsKeys.filter((lsKey) => (lsKey as string).startsWith(LS_TAB_ID_PREFIX));

        if (tab.length > ABNORMAL_TAB_COUNT) {
            tab.forEach((tabKey) => Storage.removeItem(tabKey as string));
        }

        return tab.length;
    } catch (_) {
        /* empty */
    }

    return 0;
};
