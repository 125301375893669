import sendToAnalytics, { sendBatchAnalytics } from 'HHC/Performance/SendToAnalytics';

const FPS_TIMEOUT = [1000 * 60 * 5, 1000 * 60 * 10];

interface MarkFPS {
    detail: string;
}

const sendFPS = (metricName: string, isBatch = true) => {
    const fps = performance.getEntriesByName('fps');

    if (fps.length === 0) {
        return;
    }

    const fpsValues = fps.map((mark) => {
        return parseFloat((mark as unknown as MarkFPS).detail);
    });

    performance.clearMarks('fps');

    const avg =
        fpsValues.reduce((result, value) => {
            result += value;
            return result;
        }, 0) /
            fpsValues.length +
        1;

    const minMetric = {
        metricName: `${metricName}-min`,
        value: Math.min(...fpsValues),
    };
    const maxMetric = {
        metricName: `${metricName}-max`,
        value: Math.max(...fpsValues),
    };
    const avgMetric = {
        metricName: `${metricName}-avg`,
        value: avg,
    };

    if (isBatch) {
        sendBatchAnalytics([minMetric, maxMetric, avgMetric]);
    } else {
        sendToAnalytics(minMetric.metricName, minMetric.value);
        sendToAnalytics(maxMetric.metricName, maxMetric.value);
        sendToAnalytics(avgMetric.metricName, avgMetric.value);
    }
};

window.addEventListener('load', () => {
    // Метрику FPS стартовой загрузки не шлем батчем, потому как она попадает в самый первый батч метрик перфа
    sendFPS('first-load-fps', false);
});

window.addEventListener('beforeunload', () => {
    const notSendedFPS = FPS_TIMEOUT.filter((timeoutMs) => timeoutMs < performance.now());

    if (notSendedFPS.length > 0) {
        sendFPS('work-load-fps');
    }
});

FPS_TIMEOUT.forEach((timeoutMs) => {
    setTimeout(() => {
        sendFPS('work-load-fps');
    }, timeoutMs);
});
